import { useFrame } from "@react-three/fiber";
import { easing } from "maath";
import React, { FC, PropsWithChildren } from "react";
import { Vector3 } from "three";
import { useSnapshot } from "valtio";

import { state } from "utils/store";

const CameraComponent: FC<PropsWithChildren<{}>> = ({ children }) => {
  const snapshot = useSnapshot(state);

  useFrame((frameState, delta) => {
    easing.dampE(
      state.camera.mouseRotation,
      [
        frameState.pointer.y / 30,
        -frameState.pointer.x / 15,
        snapshot.camera.rotation.z,
      ],
      0.3,
      delta
    );
  });

  return <group position={new Vector3(0, -1.44, 6.16)}>{children}</group>;
};

export default CameraComponent;
