import { FC, PropsWithChildren } from "react";

import "./InfoPopup.scss";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

interface IInfoPopup {
  media: string;
  mediaType?: "image" | "video";
  videoThumbnail?: string;
  author?: string;
  isOpen: boolean;
  handleClose: () => void;
}

const InfoPopup: FC<PropsWithChildren<IInfoPopup>> = ({
  isOpen,
  handleClose,
  media,
  children,
  author,
  mediaType = "image",
  videoThumbnail,
}) => {
  return (
    <div className={"popup" + (isOpen ? " opened" : "")}>
      <div className="popup__close" onClick={handleClose}>
        <CloseIcon />
      </div>
      <div className="popup__content">
        {mediaType === "image" ? (
          <img src={media} alt="Popup" className="popup__image" />
        ) : (
          <video
            src={media}
            className="popup__video"
            controls
            poster={videoThumbnail}
          ></video>
        )}

        <div className="popup__content-container">
          <div className="popup__content__text">{children}"</div>
          {author && <div className="popup__author">- {author}</div>}
        </div>
      </div>

      <div className="popup__button" onClick={handleClose}>
        BACK TO EXHIBITION
      </div>
    </div>
  );
};

export default InfoPopup;
