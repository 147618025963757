import { PositionalAudio } from "@react-three/drei";
import React from "react";
import { Vector3 } from "three";
import { useSnapshot } from "valtio";

import { state } from "utils/store";

const Audios = () => {
  const snap = useSnapshot(state);

  return (
    <>
      {!snap.hero && snap.timeline < 5 && (
        <PositionalAudio
          autoplay
          position={new Vector3(0, 1.6, -16.6)}
          url="./audios/first-scene-audio.mp3"
          distance={0.5}
          loop
        />
      )}
      {!snap.hero && snap.timeline > 11.35 && snap.timeline < 17.55 && (
        <PositionalAudio
          autoplay
          position={new Vector3(0, 5.6, -18)}
          url="./audios/third-scene-audio.mp3"
          distance={1}
          loop
        />
      )}
    </>
  );
};

export default Audios;
