import "./Hero.scss";
import gsap from "gsap";
import { useEffect } from "react";
import { useSnapshot } from "valtio";

import splashTitleImg from "assets/images/SPLASH_TITLE.png";
// import { isMobile } from "utils/isMobile";
import { state } from "utils/store";

const Hero = () => {
  const snapshot = useSnapshot(state);

  useEffect(() => {
    if (snapshot.hero) {
      gsap.to(".hero", {
        duration: 2,
        y: "0",
      });
    } else {
      gsap.to(".hero", {
        duration: 2,
        y: "-100%",
      });
    }
  }, [snapshot.hero]);

  const handleExitHero = () => {
    if (snapshot.hero) {
      state.hero = false;
    }
  };

  return (
    <div
      id="hero"
      className="hero"
      // onScroll={handleExitHero}
      // onWheel={handleExitHero}
      // onClick={handleExitHero}
    >
      <video
        src="/videos/SPLASH_TEXTURE.mp4"
        autoPlay
        muted
        loop
        playsInline
        className="hero__video"
      ></video>
      <div className="hero__content">
        <div></div>
        <img src={splashTitleImg} className="hero__title" alt="splash title" />

        {/* <div className="hero__text-wrapper">
          <div className="hero__text">CLICK TO EXPLORE</div>
        </div> */}

        <div className="hero__button" onClick={handleExitHero}>
          START GUIDED TOUR
        </div>
      </div>
    </div>
  );
};

export default Hero;
