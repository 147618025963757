import { MeshReflectorMaterial, useVideoTexture } from "@react-three/drei";
import { FC } from "react";
import { Euler, Vector3 } from "three";

import Plane from "./components/Plane";

const VideoComponent: FC = () => {
  return (
    <>
      <SingleVideo
        position={new Vector3(-12.7, 6.65, -22.6)}
        rotation={new Euler(0, Math.PI / 2, 0)}
        width={6.5}
        video="/videos/video-wall.mp4"
      />

      <SingleVideo
        position={new Vector3(0, 4.36, -22.85)}
        rotation={new Euler(-Math.PI / 2, 0, 0)}
        width={7.2}
        video="/videos/video-pool.mp4"
      />

      <SingleVideo
        position={new Vector3(12.7, 6.65, -22.6)}
        rotation={new Euler(0, -Math.PI / 2, 0)}
        width={6.5}
        video="/videos/video-pool.mp4"
      />
    </>
  );
};

function VideoMaterial({ url }: { url: string }) {
  const texture = useVideoTexture(url, {});

  return (
    <MeshReflectorMaterial
      mirror={0}
      mixBlur={20}
      mixStrength={5}
      opacity={0.7}
      transparent
      minDepthThreshold={0.8}
      maxDepthThreshold={1.5}
      depthToBlurRatioBias={0.5}
      metalness={0.5}
      roughness={1}
      map={texture}
    />
  );
}

interface ISingleVideo {
  position: Vector3;
  rotation?: Euler;
  width: number;
  video: string;
}

const SingleVideo: FC<ISingleVideo> = ({
  position,
  rotation,
  width,
  video,
}) => {
  return (
    <group position={position} rotation={rotation}>
      <Plane width={width} height={width / 1.77777777778}>
        <VideoMaterial url={video} />
      </Plane>
    </group>
  );
};

export default VideoComponent;
