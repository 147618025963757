import { useProgress } from "@react-three/drei";
import "./LoaderLayout.scss";
import * as React from "react";

interface LoaderOptions {
  initialState: (active: boolean) => boolean;
}

export function LoaderLayout({
  initialState = (active: boolean) => active,
}: Partial<LoaderOptions>) {
  const { active, progress } = useProgress();

  const progressRef = React.useRef(0);
  const rafRef = React.useRef(0);
  const progressSpanRef = React.useRef<HTMLSpanElement>(null);
  const [shown, setShown] = React.useState(initialState(active));

  React.useEffect(() => {
    let t: any;
    if (active !== shown) t = setTimeout(() => setShown(active), 1000);
    return () => clearTimeout(t);
  }, [shown, active]);

  const updateProgress = React.useCallback(() => {
    if (!progressSpanRef.current) return;
    progressRef.current += (progress - progressRef.current) / 2;
    if (progressRef.current > 0.95 * progress || progress === 100)
      progressRef.current = progress;
    if (progressRef.current < progress)
      rafRef.current = requestAnimationFrame(updateProgress);
  }, [progress]);

  React.useEffect(() => {
    updateProgress();
    return () => cancelAnimationFrame(rafRef.current);
  }, [updateProgress]);

  if (!shown) return null;

  const loaderProgress = document.querySelector(".loader__progress");
  if (loaderProgress) {
    loaderProgress.innerHTML = `Exhibition Loading ${progress.toFixed(2)}%`;
  }

  return (
    <div className={`loader ${active ? "active" : ""}`}>
      <div className="loader__icons-wrapper">
        <div className="loader__circle"></div>
      </div>

      <div className="loader__progress-container">
        <span className="loader__progress">Exhibition Loading 0%</span>
      </div>
    </div>
  );
}
