/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import { Vector3 } from "three";

import scrollIcon from "assets/images/SCROLL_NO_ARROW.png";
import soundOnIcon from "assets/images/SOUND_ON.png";
import { isMobile } from "utils/isMobile";

import CanvasComponent from "./Canvas/Canvas";
import Model from "./Canvas/Model/Model";
import PinComponent from "./Canvas/Model/Pin";
import TextComponent from "./Canvas/Model/Text";
import TextGraphicsComponent from "./Canvas/Model/TextGraphics";
import VideoComponent from "./Canvas/Model/Video";
import Hero from "./Layout/Hero/Hero";
import InstallationPanel from "./Layout/InstallationPanel/InstallationPanel";
import Layout from "./Layout/Layout";
import SharingPanel from "./Layout/SharingPanel/SharingPanel";
import Modals from "./Modals";

let scrollTimeout: NodeJS.Timeout | undefined;
const DashboardPage = () => {
  const [showScroll, setShowScroll] = useState(true);

  useEffect(() => {
    const scrollListener = () => {
      clearTimeout(scrollTimeout);
      setShowScroll(false);

      scrollTimeout = setTimeout(() => {
        setShowScroll(true);
      }, 5000);
    };

    document.addEventListener("scroll", scrollListener);
    document.addEventListener("wheel", scrollListener);
    return () => {
      clearTimeout(scrollTimeout);
      document.removeEventListener("scroll", scrollListener);
      document.removeEventListener("wheel", scrollListener);
    };
  });

  return (
    <Layout>
      <Hero />

      <CanvasComponent
        position={new Vector3(0, 0, 0)}
        fov={isMobile() ? 105 : 80}
        far={isMobile() ? 40 : 100}
      >
        <Model />

        <TextComponent />
        <TextGraphicsComponent />
        <PinComponent />
        <VideoComponent />
      </CanvasComponent>
      <InstallationPanel />

      <img
        src={scrollIcon}
        className={`scroll-icon ${!showScroll ? "hidden" : ""}`}
        alt="Scroll"
      />
      <img src={scrollIcon} className={`scroll-icon-2`} alt="Scroll" />
      <img src={soundOnIcon} className="sound-on" alt="Sound On" />

      <SharingPanel />
      <Modals />
    </Layout>
  );
};

export default DashboardPage;
