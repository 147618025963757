import { Text } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import React, { FC, PropsWithChildren, useMemo, useRef } from "react";
import { Euler, Vector3 } from "three";
import { useSnapshot } from "valtio";

import { state } from "utils/store";

const TextComponent = () => {
  return (
    <>
      <group>
        <SingleText
          disappear={0.04}
          type="h1"
          bold
          position={new Vector3(0, 0.8, -8)}
          appear={0.0001}
        >
          Standard Bank Gallery
        </SingleText>
        <SingleText
          disappear={0.04}
          type="p"
          position={new Vector3(0, 0.67, -8)}
          appear={0.0001}
        >
          Johannesburg, South Africa
        </SingleText>
      </group>

      {/* <group>
        <SingleText
          anchorX="left"
          appear={1.3}
          disappear={2}
          type="h1"
          bold
          position={new Vector3(2.2, 1.6, -16.6)}
        >
          Ilnkwenkwezi
        </SingleText>
        <SingleText
          anchorX="left"
          appear={1.3}
          disappear={2}
          type="p"
          position={new Vector3(2.2, 1.45, -16.6)}
        >
          2022
        </SingleText>
        <SingleText
          anchorX="left"
          appear={1.3}
          disappear={2}
          type="p"
          position={new Vector3(2.2, 1.3, -16.6)}
        >
          Wool, Eucalyptus tree stumps,
        </SingleText>
        <SingleText
          anchorX="left"
          appear={1.3}
          disappear={2}
          type="p"
          position={new Vector3(2.2, 1.2, -16.6)}
        >
          grass and soil.
        </SingleText>
      </group>

      <group>
        <SingleText
          anchorX="left"
          appear={6}
          disappear={7.2}
          type="h1"
          bold
          position={new Vector3(8.3, 1.7, -6.6)}
        >
          ilangalialele
        </SingleText>
        <SingleText
          anchorX="left"
          appear={6}
          disappear={7.2}
          type="p"
          position={new Vector3(8.3, 1.55, -6.6)}
        >
          2022
        </SingleText>
        <SingleText
          anchorX="left"
          appear={6}
          disappear={7.2}
          type="p"
          position={new Vector3(8.3, 1.4, -6.6)}
        >
          Instalation, steel, wood, imphepho,
        </SingleText>
        <SingleText
          anchorX="left"
          appear={6}
          disappear={7.2}
          type="p"
          position={new Vector3(8.3, 1.3, -6.6)}
        >
          soil, imbola, umkando and holy ash
        </SingleText>
      </group>

      <group>
        <SingleText
          anchorX="left"
          disappear={12}
          type="h1"
          bold
          position={new Vector3(-0.4, 5.6, -15)}
        >
          Yehla Moya
        </SingleText>
        <SingleText
          anchorX="left"
          disappear={12}
          type="p"
          position={new Vector3(-0.4, 5.45, -15)}
        >
          2022
        </SingleText>
        <SingleText
          anchorX="left"
          disappear={12}
          type="p"
          position={new Vector3(-0.4, 5.3, -15)}
        >
          Video Installation 10'05''
        </SingleText>
      </group> */}
    </>
  );
};

interface ISingleText {
  type: "h1" | "p";
  bold?: boolean;
  position: Vector3;
  rotation?: Euler;
  anchorX?: "center" | "left" | "right";
  appear?: number;
  disappear?: number;
}

const SingleText: FC<PropsWithChildren<ISingleText>> = ({
  bold,
  type,
  position,
  children,
  rotation,
  anchorX = "center",
  appear,
  disappear,
}) => {
  // const tl = useRef<gsap.core.Timeline | null>(null);
  const group = useRef<any>(null);
  const camera = useThree((state) => state.camera);

  const snapshot = useSnapshot(state);

  useFrame((state, delta) => {
    if (group.current) {
      // easing.damp3(
      //   group.current.position,
      //   [
      //     position.x + state.pointer.x / 90,
      //     position.y + state.pointer.y / 90,
      //     position.z,
      //   ],
      //   0.3,
      //   delta
      // );

      group.current.lookAt(
        new Vector3(
          camera.position.x,
          camera.position.y - 0.5,
          camera.position.z
        )
      );
    }
  });

  const setFillOpacity = (method: "+" | "-") => {
    if (method === "+") {
      group.current.fillOpacity = Math.min(group.current.fillOpacity + 0.05, 1);
      group.current.outlineOpacity = Math.min(
        group.current.outlineOpacity + 0.05,
        1
      );
    } else if (method === "-") {
      group.current.fillOpacity = Math.max(group.current.fillOpacity - 0.05, 0);
      group.current.outlineOpacity = Math.max(
        group.current.outlineOpacity - 0.05,
        0
      );
    }
  };
  const setY = (method: "+" | "-") => {
    if (method === "+") {
      group.current.position.y = Math.min(
        group.current.position.y + 0.01,
        position.y + 0.2
      );
    } else if (method === "-") {
      group.current.position.y = Math.max(
        group.current.position.y - 0.01,
        position.y
      );
    }
  };

  useFrame(() => {
    if (appear && !disappear && group.current) {
      if (snapshot.timeline > appear) {
        setFillOpacity("+");
        setY("-");
      } else {
        setFillOpacity("-");
        setY("+");
      }
    }

    if (disappear && !appear && group.current) {
      if (snapshot.timeline > disappear) {
        setFillOpacity("-");
        setY("+");
      } else {
        setFillOpacity("+");
        setY("-");
      }
    }

    if (disappear && appear && group.current) {
      if (snapshot.timeline > appear && snapshot.timeline <= disappear) {
        setFillOpacity("+");
        setY("-");
      } else {
        setFillOpacity("-");
        setY("+");
      }
    }
  });

  // Defining Text Props
  const props = useMemo(() => {
    let textProps: { [key: string]: any } = {
      color: "#FFF",
    };

    if (bold) {
      textProps = {
        ...textProps,
        outlineColor: "#fff",
        outlineWidth: 0.0015,
        outlineOpacity: appear ? 0 : 1,
      };
    }

    if (type) {
      textProps = {
        ...textProps,
      };
    }

    switch (type) {
      case "h1":
        textProps = {
          ...textProps,
          fontSize: 0.13,
        };
        break;
      case "p":
      default:
        textProps = {
          ...textProps,
          fontSize: 0.08,
        };
        break;
    }

    return textProps;
  }, [bold, type, appear]);

  return (
    <>
      <Text
        ref={group}
        {...props}
        fillOpacity={appear ? 0 : 1}
        anchorX={anchorX}
        rotation={rotation}
        position={position}
      >
        {children}
      </Text>
    </>
  );
};

export default TextComponent;
