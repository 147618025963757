import { Canvas } from "@react-three/fiber";
import React, { FC, PropsWithChildren } from "react";
import { Vector3 } from "three";

import CameraComponent from "./Camera/Camera";
import ScrollControlsComponent from "./Controls/ScrollControls";
import EnvironmentComponent from "./Environment/Environment";
import LightComponent from "./Light/Light";
import LoaderComponent from "./Loader/Loader";

interface ICanvasComponent {
  position: Vector3;
  fov: number;
  far: number;
}

const CanvasComponent: FC<PropsWithChildren<ICanvasComponent>> = ({
  position,
  fov,
  far,
  children,
}) => {
  return (
    <>
      <Canvas
        // frameloop="demand"
        // dpr={1}
        // gl={{ preserveDrawingBuffer: true }}
        camera={{ position, fov, far }}
        eventPrefix="client"
        style={{ touchAction: "none" }}
      >
        <EnvironmentComponent />
        <LightComponent />

        <ScrollControlsComponent>
          <CameraComponent>{children}</CameraComponent>
        </ScrollControlsComponent>
      </Canvas>
      <LoaderComponent />
    </>
  );
};

export default CanvasComponent;
