import { Environment, Stage } from "@react-three/drei";
import React from "react";
import { suspend } from "suspend-react";

const EnvironmentComponent = () => {
  const citySuspend = suspend(async () => {
    //@ts-expect-error
    const city = await import("@pmndrs/assets/hdri/city.exr");
    return city.default;
  }, []);

  return (
    <>
      <Stage>
        <Environment files={citySuspend} />
      </Stage>
    </>
  );
};

export default EnvironmentComponent;
