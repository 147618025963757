import { Billboard, Image, Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { FC, useRef } from "react";
import { Vector3 } from "three";
import { useSnapshot } from "valtio";

import { state } from "utils/store";

const PinComponent: FC = () => {
  return (
    <>
      <SinglePin
        onClick={() => {
          state.modal = "info1modal";
        }}
        appear={1.5}
        position={new Vector3(2.3, 1.2, -15.7)}
        showText
      />

      <SinglePin
        onClick={() => {
          state.modal = "info12modal";
        }}
        appear={3.3}
        position={new Vector3(-1.5, 0.7, -18.5)}
        showText
      />

      <SinglePin
        onClick={() => {
          state.modal = "info21modal";
        }}
        appear={6.8}
        disappear={9.3}
        position={new Vector3(8.8, 0.7, -4.8)}
        showText
      />

      <SinglePin
        onClick={() => {
          state.modal = "info22modal";
        }}
        appear={9.5}
        position={new Vector3(12, 0.6, -4.8)}
        showText
      />

      <SinglePin
        onClick={() => {
          state.modal = "info31modal";
        }}
        appear={13.3}
        position={new Vector3(-3.7, 5.1, -21)}
        showText
      />

      <SinglePin
        onClick={() => {
          state.modal = "info32modal";
        }}
        appear={15}
        position={new Vector3(-7, 5.25, -21.3)}
        showText
      />
    </>
  );
};
interface ISnglePin {
  position: Vector3;
  showText?: boolean;
  appear?: number;
  disappear?: number;
  onClick: () => void;
}

const SinglePin: FC<ISnglePin> = ({
  onClick,
  position,
  showText = false,
  appear,
  disappear,
}) => {
  const snapshot = useSnapshot(state);
  const group = useRef<any>(null);

  const image = useRef<any>(null);
  const text = useRef<any>(null);
  const text2 = useRef<any>(null);

  const setFillOpacity = (method: "+" | "-") => {
    if (method === "+") {
      text.current.fillOpacity = Math.min(text.current.fillOpacity + 0.05, 1);
      text2.current.fillOpacity = Math.min(text2.current.fillOpacity + 0.05, 1);
      image.current.material.opacity = Math.min(
        image.current.material.opacity + 0.05,
        1
      );
    } else if (method === "-") {
      text.current.fillOpacity = Math.max(text.current.fillOpacity - 0.05, 0);
      text2.current.fillOpacity = Math.max(text2.current.fillOpacity - 0.05, 0);
      image.current.material.opacity = Math.max(
        image.current.material.opacity - 0.05,
        0
      );
    }
  };
  const setY = (method: "+" | "-") => {
    if (method === "+") {
      group.current.position.y = Math.min(
        group.current.position.y + 0.01,
        position.y + 0.2
      );
    } else if (method === "-") {
      group.current.position.y = Math.max(
        group.current.position.y - 0.01,
        position.y
      );
    }
  };

  useFrame(() => {
    if (
      appear &&
      !disappear &&
      group.current &&
      text.current &&
      image.current
    ) {
      if (snapshot.timeline > appear) {
        setFillOpacity("+");
        setY("-");
      } else {
        setFillOpacity("-");
        setY("+");
      }
    }
    if (
      disappear &&
      !appear &&
      group.current &&
      text.current &&
      image.current
    ) {
      if (snapshot.timeline > disappear) {
        setFillOpacity("-");
        setY("+");
      } else {
        setFillOpacity("+");
        setY("-");
      }
    }

    if (disappear && appear && group.current && text.current && image.current) {
      if (snapshot.timeline > appear && snapshot.timeline <= disappear) {
        setFillOpacity("+");
        setY("-");
      } else {
        setFillOpacity("-");
        setY("+");
      }
    }
  });

  return (
    <group
      position={position}
      onClick={() => {
        onClick();
      }}
      ref={group}
    >
      <Billboard follow lockX={false} lockY={false} lockZ={false}>
        <Image
          ref={image}
          opacity={appear ? 0 : 1}
          transparent
          url={`/icons/pin.svg`}
          scale={0.1}
        />
        {/* {showText && (
        <> */}
        <Text
          ref={text}
          fillOpacity={appear ? 0 : 1}
          scale={0.05}
          position={new Vector3(0, -0.1, 0)}
        >
          SELECT
        </Text>
        <Text ref={text2} scale={0.05} position={new Vector3(0, -0.16, 0)}>
          to learn more
        </Text>
      </Billboard>
    </group>
  );
};

export default PinComponent;
