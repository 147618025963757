import { ScrollControls } from "@react-three/drei";
import React, { FC, PropsWithChildren } from "react";

const ScrollControlsComponent: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <ScrollControls pages={120} damping={0.1}>
      {children}
    </ScrollControls>
  );
};

export default ScrollControlsComponent;
