import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React from "react";

import DashboardPage from "containers/dashboard";

const firebaseConfig = {
  apiKey: "AIzaSyB2BEWkf0TYfqp50GB0kCoXC9oVEOpqwDE",
  authDomain: "buhlebezwe-dbbbc.firebaseapp.com",
  projectId: "buhlebezwe-dbbbc",
  storageBucket: "buhlebezwe-dbbbc.appspot.com",
  messagingSenderId: "808417288704",
  appId: "1:808417288704:web:9db2c8e1639297458d2ee5",
  measurementId: "G-NW945M723M",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
// console.log(analytics);

function App() {
  return (
    <>
      <DashboardPage />
    </>
  );
}

export default App;
