import { Billboard, Image } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { FC, useRef } from "react";
import { Vector3 } from "three";
import { useSnapshot } from "valtio";

import { state } from "utils/store";

const TextGraphicsComponent: FC = () => {
  return (
    <>
      <SingleTextGraphic
        image="./icons/installation-title-1.png"
        scale={[1.05, 0.45]}
        appear={0.5}
        disappear={0.65}
        position={new Vector3(0, 1.3, -13.5)}
      />

      <SingleTextGraphic
        image="./icons/installation-title-2.png"
        scale={[1.2, 0.45]}
        // appear={5.1}
        disappear={5.4}
        position={new Vector3(4, 1.3, -5.5)}
      />

      <SingleTextGraphic
        image="./icons/installation-title-3.png"
        scale={[2.3, 0.9]}
        // appear={7}
        disappear={12}
        position={new Vector3(-0, 6, -16)}
      />
    </>
  );
};
interface ISingleTextGraphic {
  image: string;
  scale: [number, number];
  position: Vector3;
  appear?: number;
  disappear?: number;
}

const SingleTextGraphic: FC<ISingleTextGraphic> = ({
  scale,
  image: imageUrl,
  position,
  appear,
  disappear,
}) => {
  const snapshot = useSnapshot(state);
  const group = useRef<any>(null);

  const image = useRef<any>(null);

  const setFillOpacity = (method: "+" | "-") => {
    if (method === "+") {
      image.current.material.opacity = Math.min(
        image.current.material.opacity + 0.05,
        1
      );
    } else if (method === "-") {
      image.current.material.opacity = Math.max(
        image.current.material.opacity - 0.05,
        0
      );
    }
  };
  const setY = (method: "+" | "-") => {
    if (method === "+") {
      group.current.position.y = Math.min(
        group.current.position.y + 0.01,
        position.y + 0.2
      );
    } else if (method === "-") {
      group.current.position.y = Math.max(
        group.current.position.y - 0.01,
        position.y
      );
    }
  };

  useFrame(() => {
    if (appear && !disappear && group.current && image.current) {
      if (snapshot.timeline > appear) {
        setFillOpacity("+");
        setY("-");
      } else {
        setFillOpacity("-");
        setY("+");
      }
    }
    if (disappear && !appear && group.current && image.current) {
      if (snapshot.timeline > disappear) {
        setFillOpacity("-");
        setY("+");
      } else {
        setFillOpacity("+");
        setY("-");
      }
    }

    if (disappear && appear && group.current && image.current) {
      if (snapshot.timeline > appear && snapshot.timeline <= disappear) {
        setFillOpacity("+");
        setY("-");
      } else {
        setFillOpacity("-");
        setY("+");
      }
    }
  });

  return (
    <group position={position} ref={group}>
      <Billboard follow lockX={false} lockY={false} lockZ={false}>
        <Image
          ref={image}
          opacity={appear ? 0 : 1}
          transparent
          url={imageUrl}
          scale={scale}
        />
      </Billboard>
    </group>
  );
};

export default TextGraphicsComponent;
