import { useGLTF, useScroll } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import gsap from "gsap";
import React, { FC, Suspense, useLayoutEffect, useRef } from "react";
import { Group } from "three";
import { useSnapshot } from "valtio";

import Audios from "containers/dashboard/Layout/Audios/Audios";
import { state } from "utils/store";
interface IModel {
  // position: Vector3;
  // rotation: Euler;
  // scale: Vector3;
}

const Model: FC<IModel> = () => {
  const snapshot = useSnapshot(state);

  // SCENE
  const ref = useRef<Group>(null);
  const gltf = useGLTF("./models/scene.glb", true);
  const scene = gltf.scene;
  scene.children = scene.children.filter(
    (child) =>
      child.name !== "Video_Pool_v01" &&
      child.name !== "Video_Wall_01" &&
      child.name !== "Video_Wall_02" &&
      child.name !== "Plane634"
  );

  const primitiveProps = {
    ref,
    object: scene,
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    scale: [1, 1, 1],
    castShadow: true,
  };

  // ANIMATIONS
  const tl = useRef<gsap.core.Timeline | null>(null);

  const scroll = useScroll();

  const camera = useThree((state) => state.camera);

  useFrame(() => {
    if (tl.current) {
      state.timeline = scroll.offset * tl.current.duration();
      tl.current.seek(scroll.offset * tl.current.duration());
    }
  });

  useLayoutEffect(() => {
    const sharingPanel = document.querySelector(".sharing-panel");
    tl.current = gsap.timeline({});

    // SOUND ON

    tl.current
      .to(
        ".sound-on",
        {
          duration: 0.1,
          opacity: 1,
        },
        0.1
      )
      .to(
        ".sound-on",
        {
          duration: 0.1,
          opacity: 0,
        },
        0.4
      );

    // FIRST SCENE
    tl.current
      .to(
        state.camera.position,
        {
          duration: 1,
          z: -8,
        },
        0
      )
      .to(
        state.camera.position,
        {
          duration: 0.15,
          y: -0.1,
          z: -8.1,
        },
        1
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          z: -9.7,
          x: 1.45,
        },
        1.15
      )
      .to(
        state.camera.rotation,
        {
          duration: 1,
          y: -1.7,
          z: -0.3,
          x: -0.3,
        },
        1.15
      )
      .to(
        state.camera.position,
        {
          duration: 0.2,
          z: -11,
          x: 1.45,
        },
        2.15
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          z: -12.5,
          x: 0,
        },
        2.35
      )
      .to(
        state.camera.rotation,
        {
          duration: 0.4,
          z: 0,
          x: 0,
        },
        2.15
      )
      .to(
        state.camera.rotation,
        {
          duration: 1.2,
          y: 1.8,
        },
        2.15
      )
      .to(
        state.camera.position,
        {
          duration: 0.5,
          z: -12.5,
          x: -0.5,
        },
        3.35
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          z: -11.5,
          x: -1,
        },
        3.85
      )
      .to(
        state.camera.rotation,
        {
          duration: 1,
          y: 1.6,
        },
        3.85
      );

    tl.current
      .to(
        ".installation-panel",
        {
          opacity: 1,
          duration: 0.3,
        },
        4.55
      )
      .to(
        ".installation-panel",
        {
          opacity: 0,
          duration: 0.5,
        },
        4.85
      )
      .to(
        ".scroll-icon-2",
        {
          opacity: 1,
          duration: 0.3,
        },
        4.55
      )
      .to(
        ".scroll-icon-2",
        {
          opacity: 0,
          duration: 0.3,
        },
        4.85
      );

    // SECOND SCENE
    tl.current
      .to(
        state.camera.position,
        {
          duration: 0,
          z: 0.7,
          x: 0,
        },
        4.85
      )
      .to(
        state.camera.rotation,
        {
          duration: 0,
          y: -1.57,
        },
        4.85
      )
      .to(
        state.camera.position,
        {
          duration: 2,
          z: 0.7,
          x: 7,
        },
        4.85
      )
      .to(
        state.camera.rotation,
        {
          duration: 2,
          y: -1.35,
        },
        4.85
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          z: 3.2,
          x: 9,
        },
        6.85
      )
      .to(
        state.camera.rotation,
        {
          duration: 1,
          y: -0.87,
        },
        6.85
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          z: 5,
          x: 13.3,
        },
        7.85
      )
      .to(
        state.camera.rotation,
        {
          duration: 1,
          y: -0.47,
        },
        7.85
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          z: 2,
        },
        8.85
      )
      .to(
        state.camera.rotation,
        {
          duration: 1,
          x: -0.3,
          y: 0.95,
          z: 0.3,
        },
        8.85
      )
      .to(
        state.camera.position,
        {
          duration: 0.5,
          z: 1.5,
          x: 12.7,
        },
        9.85
      )
      .to(
        state.camera.rotation,
        {
          duration: 0.5,
          x: -1.4,
          z: 1.4,
        },
        9.85
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          z: -4.5,
          x: 9.2,
        },
        10.35
      )
      .to(
        state.camera.rotation,
        {
          duration: 1,
          x: 0,
          z: 0,
          // y: -0.5,
        },
        10.35
      );

    tl.current
      .to(
        ".installation-panel",
        {
          opacity: 1,
          duration: 0.3,
        },
        11.05
      )
      .to(
        ".installation-panel",
        {
          opacity: 0,
          duration: 0.5,
        },
        11.35
      )
      .to(
        ".scroll-icon-2",
        {
          opacity: 1,
          duration: 0.3,
        },
        11.05
      )
      .to(
        ".scroll-icon-2",
        {
          opacity: 0,
          duration: 0.3,
        },
        11.35
      );

    // SECOND

    // THIRD SCENE
    tl.current
      .to(
        state.camera.position,
        {
          duration: 0,
          x: -0.15,
          y: 3.2,
          z: -6,
        },
        11.35
      )
      .to(
        state.camera.rotation,
        {
          duration: 0,
          // y: -6.4,
          y: -0.21,
        },
        11.35
      )
      .to(
        state.camera.position,
        {
          duration: 2,
          z: -9,
          y: 4,
        },
        11.35
      )
      .to(
        state.camera.position,
        {
          duration: 2,
          z: -15.5,
          x: -5,
        },
        13.35
      )
      .to(
        state.camera.rotation,
        {
          duration: 2,
          y: -1,
          x: -0.8,
          z: -0.8,
        },
        13.35
      )
      .to(
        state.camera.rotation,
        {
          duration: 0.2,
          x: 0,
          z: 0,
        },
        15.35
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          x: -5.5,
        },
        15.55
      )
      .to(
        state.camera.rotation,
        {
          duration: 1,
          y: 1.4,
        },
        15.55
      )
      .to(
        state.camera.position,
        {
          duration: 1,
          x: -7,
          z: -15,
        },
        16.55
      )
      .to(
        state.camera.rotation,
        {
          duration: 1,
          y: 2.4,
        },
        16.55
      )
      .to(
        sharingPanel,
        {
          translateY: "0%",
          duration: 0.25,
        },
        17.55
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFrame(() => {
    camera.position.set(
      snapshot.camera.position.x,
      snapshot.camera.position.y,
      snapshot.camera.position.z
    );
    camera.rotation.set(
      snapshot.camera.rotation.x,
      snapshot.camera.rotation.y + snapshot.camera.mouseRotation.y,
      snapshot.camera.rotation.z
    );
  });

  return (
    <>
      <Suspense fallback={<></>}>
        <primitive {...primitiveProps}></primitive>
        <Audios />
      </Suspense>
    </>
  );
};

// Preload all models
useGLTF.preload("./models/scene.glb", true);

export default React.memo(Model);
