import React, { useState } from "react";
import "./header.scss";

const Header = () => {
  const [headerActive, setHeaderActive] = useState(false);

  return (
    <header className="header">
      <div className="header__container">
        <a href="#!">
          <img src="/logo.svg" alt="Logo" />
        </a>
        <div
          onClick={() => setHeaderActive((p) => !p)}
          className={"header__hamburger" + (headerActive ? " open" : "")}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={"header__dropdown" + (headerActive ? " open" : "")}>
        <a
          className="header__dropdown__link"
          href="https://arts.standardbank.co.za/"
        >
          Virtual Arts Home
        </a>
        <a
          className="header__dropdown__link"
          href="https://sponsorships.standardbank.com/"
        >
          Sponsorships Home
        </a>
        <a
          className="header__dropdown__link"
          href="https://standardbankgallery.store.simplyblu.co.za/product/the-resilient-visionary-poetic-expressions-of-david-kol"
        >
          Shop
        </a>
        <div className="header__dropdown__link parent-link">
          <div className="header__dropdown__link__text">Exhibition Archive</div>
          <div className="header__dropdown__link__items">
            <a href="https://arts.standardbank.co.za/?roomSel=culture&privatemode=false">
              We Are Culture
            </a>
            <a href="https://arts.standardbank.co.za/?roomSel=mother&privatemode=false">
              Photographs in Our Mother Tongue
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
