import "./SharingPanel.scss";
import { useState } from "react";

import closeIcon from "assets/icons/closeOutlined.svg";
import facebookIcon from "assets/icons/facebook.png";
import linkedinIcon from "assets/icons/linkedin.png";
import twitterIcon from "assets/icons/twitter.png";
import { state } from "utils/store";

let pageUrl = "https://bit.ly/427aHEu";

const SharingPanel = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(pageUrl);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleShareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}&quote=${encodeURIComponent(
        "Experience the IYEZA virtual exhibition by Buhlebezwe Siwani, Standard Bank Young Artist, 2021"
      )}`,
      "facebook-share-dialog",
      "width=626,height=436"
    );
  };

  const handleShareTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        "Experience the IYEZA virtual exhibition by Buhlebezwe Siwani, Standard Bank Young Artist, 2021"
      )}%20${pageUrl}`,
      "twitter-share-dialog",
      "width=626,height=436"
    );
  };

  const handleShareLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&source=${pageUrl}&title=${encodeURIComponent(
        "Experience the IYEZA virtual exhibition by Buhlebezwe Siwani, Standard Bank Young Artist, 2021"
      )}`,
      "linkedin-share-dialog",
      "width=626,height=436"
    );
  };

  return (
    <div className="sharing-panel">
      <video
        src="/videos/SPLASH_TEXTURE.mp4"
        autoPlay
        muted
        loop
        playsInline
        className="sharing-panel__video"
      ></video>
      <div className="sharing-panel__content">
        <div className="sharing-panel__title">SHARE THE JOURNEY</div>
        <div className="sharing-panel__share">
          <div
            className="sharing-panel__close"
            onClick={() => {
              document
                .querySelectorAll("div")
                .forEach((el) => el.scrollTo(0, 0));

              state.hero = true;
            }}
          >
            <img src={closeIcon} alt="Close" />
          </div>
          <div className="sharing-panel__icons">
            <div className="sharing-panel__btn" onClick={handleShareFacebook}>
              <img src={facebookIcon} alt="facebook" />
            </div>
            <div className="sharing-panel__btn" onClick={handleShareTwitter}>
              <img src={twitterIcon} alt="twitter" />
            </div>
            <div className="sharing-panel__btn" onClick={handleShareLinkedIn}>
              <img src={linkedinIcon} alt="linkedin" />
            </div>
          </div>
          <div className="sharing-panel__label-text">
            Experience the IYEZA virtual exhibition by Buhlebezwe Siwani,
            Standard Bank Young Artist, 2021{" "}
            <a href="https://bit.ly/427aHEu">https://bit.ly/427aHEu</a>
          </div>
          <div className="sharing-panel__label">Or copy link</div>
          <div className="sharing-panel__input-holder">
            <input
              value={pageUrl}
              disabled
              type="text"
              id="url"
              name="url"
              placeholder="URL"
            />
            <button onClick={handleCopy} className={isCopied ? "copied" : ""}>
              {isCopied ? "Copied!" : "Copy"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharingPanel;
