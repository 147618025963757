import * as THREE from "three";

export default function Plane({ width, height, children, ...props }: any) {
  const segments = 32;
  const segmentsH = segments;
  const segmentsV = segments / (width / height); // square
  const geometry = new THREE.PlaneGeometry(width, height, segmentsH, segmentsV);

  return (
    <group {...props}>
      <mesh geometry={geometry} receiveShadow castShadow>
        {children}
      </mesh>
    </group>
  );
}
