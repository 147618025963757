import { FC, PropsWithChildren } from "react";

import "./dashboard.scss";
import Header from "./Header/Header";

const Layout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="dashboard">
      <Header />
      {children}
    </div>
  );
};

export default Layout;
