import React from "react";
import { useSnapshot } from "valtio";

import infoImg12 from "assets/images/info-panels/INFO_PANEL_1.2.jpg";
import infoImg1 from "assets/images/info-panels/INFO_PANEL_1.jpg";
import infoImg21 from "assets/images/info-panels/INFO_PANEL_2.1.jpg";
import infoImg22 from "assets/images/info-panels/INFO_PANEL_2.2.jpg";
import infoImg31 from "assets/images/info-panels/INFO_PANEL_3.1-thumb.jpg";
import infoImg32 from "assets/images/info-panels/INFO_PANEL_3.2.jpg";
import { state } from "utils/store";

import InfoPopup from "./Layout/InfoPopup/InfoPopup";

const Modals = () => {
  const snapshot = useSnapshot(state);

  return (
    <>
      {snapshot.modal === "info1modal" && (
        <InfoPopup
          isOpen
          handleClose={() => {
            state.modal = "";
          }}
          media={infoImg1}
          author="BUHLEBEZWE SIWANI"
        >
          In this installation, <strong>wool and wood</strong> create a
          meditative landscape, a rumination on healing and how specific
          churches in Southern Africa choose to heal, taking the familiar and
          making it unfamiliar, the same way African spiritual practices had to
          codify their practices. I thought of how plants that are in abundance
          in Southern Africa are an ancestral root, literally and
          metaphorically, this is why I used Aloe Ferox and Helichrysum species,
          commonly known as imphepho, as my references.
        </InfoPopup>
      )}

      {snapshot.modal === "info12modal" && (
        <InfoPopup
          isOpen
          handleClose={() => {
            state.modal = "";
          }}
          media={infoImg12}
          author="BUHLEBEZWE SIWANI"
        >
          Everybody has to live off the land. Our grandmothers plant things in
          their gardens whether they live in suburbia or elokishini. My
          experience has always been that it [the land] is a part of who we are.
          When people say ‘dust to dust’ and ‘ashes to ashes’, it is a reference
          to the earth and to us as people. As people who walk the earth in a
          particular way abantu abaNtsundu have a sense of connectedness to the
          earth. Being in Sub-Saharan Africa we relate to the land differently.
          How we have been dispossessed of the land affects the way we
          experience ecology. It is seen in our ways of farming, and the ways
          bodies are buried. When a child is born, the placenta is buried back
          into the earth. When people commune with ancestors they kneel onto
          Earth. Sustenance and spirituality are tied to the earth.
        </InfoPopup>
      )}

      {snapshot.modal === "info21modal" && (
        <InfoPopup
          isOpen
          handleClose={() => {
            state.modal = "";
          }}
          media={infoImg21}
          author="BUHLEBEZWE SIWANI"
        >
          This piece which is comprised of wooden poles that have also been made
          with <strong>imphepho and imbola</strong>, is a reminder of how the
          physical landscape speaks to the spiritual landscape. The piece
          centres itself around the idea that our bodies are made up of
          constellations that are our ancestors, and that is why they are in the
          sky.
        </InfoPopup>
      )}

      {snapshot.modal === "info22modal" && (
        <InfoPopup
          isOpen
          handleClose={() => {
            state.modal = "";
          }}
          media={infoImg22}
          author="CANDICE ALLISON"
        >
          ... Siwani also uses <strong>Umkhando powder</strong> - the word
          Umkhando translated from Xhosa, means ‘repair’. Speaking to Siwani
          about the work, this is what I understood about the motivation behind
          this body of work - an attempt to repair and heal the trauma(s) passed
          down from the dying to the living, from grandmother to mother to
          child.
        </InfoPopup>
      )}

      {snapshot.modal === "info31modal" && (
        <InfoPopup
          isOpen
          handleClose={() => {
            state.modal = "";
          }}
          media={"/videos/INFO_PANEL_3.1.mp4"}
          mediaType="video"
          author="BUHLEBEZWE SIWANI"
          videoThumbnail={infoImg31}
        >
          This short film is centred around the women who labour, and ecological
          warfare as it pertains to land and water. It imagines the spirits that
          reside in the water, the caves, the plains, mountains, and forests. It
          is the women who dig for medicinal plants, who know how to keep plants
          alive, while also extracting what it is they need from the source.
        </InfoPopup>
      )}

      {snapshot.modal === "info32modal" && (
        <InfoPopup
          isOpen
          handleClose={() => {
            state.modal = "";
          }}
          media={infoImg32}
          author="BUHLEBEZWE SIWANI"
        >
          When it comes to the photographs and the videos, there is a very
          specific aesthetic that I realised I have, and I didn’t take notice of
          in the beginning. There are certain colours and certain ways it goes
          into post. There are things that I see happening in the works. When I
          start my experiments, it’s whatever goes, and then I build on that .
        </InfoPopup>
      )}
    </>
  );
};

export default Modals;
