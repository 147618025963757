import { Euler, Vector3 } from "three";
import { proxy } from "valtio";

const state = proxy({
  timeline: 0,
  camera: {
    position: new Vector3(0, 0, 0),
    rotation: new Euler(0, 0, 0),

    mouseRotation: new Euler(0, 0, 0),
  },
  hero: true,
  reset: false,
  modal: "",
});

export { state };
